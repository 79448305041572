<template>
    <div class="mapping-wrap">
        <div class="selection-area">
            <label>税收分类名称</label>
            <el-input v-model.trim="filter" @input="handleQueryImmediate" placeholder="请输入税收分类名称"></el-input>
            <el-button type="primary" @click="handleQuery">查询</el-button>
        </div>
        <div class="data-area">
            <el-table :data="tableData"
                      ref="taxClassTable"
                      highlight-current-row
                      v-loading="tableLoading"
                      border
                      stripe
                      :header-cell-style="handleHeaderCellStyle" max-height="400px"
                      style="width: 100%" @current-change="handleCurrentChange">
                <el-table-column prop="taxClassCode" label="税收分类编码"></el-table-column>
                <el-table-column prop="taxClassName" label="税收分类名称"></el-table-column>
                <el-table-column prop="merchandiseName" label="商品名称"></el-table-column>
                <!-- <el-table-column prop="similarity" label="相似度"></el-table-column> -->
                <el-table-column prop="taxRate" label="税率"></el-table-column>
                <el-table-column prop="specialManagement" label="增值税特殊管理"></el-table-column>
                <el-table-column prop="description" label="说明" show-overflow-tooltip></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {tableStyle} from "@/util/mixins";
    import {getTaxClassMerchandises} from "@/service/merchandise.js"

    export default {
        name: "TaxClassTaxMapping",
        mixins: [tableStyle],
        data() {
            return {
                filter: "",
                tableLoading: false,
                timeout: null,
                tableData: []
            }
        },
        methods: {
            handleQueryImmediate() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.handleQuery();
                }, 1000);
            },
            // 查询
            async handleQuery() {
                if (this.filter == '' || this.filter == null) {
                    this.toast("查询参数不可为空~", "warning");
                    return;
                }
                this.tableLoading = true;
                const {success, data} = await getTaxClassMerchandises(this.filter);
                this.tableLoading = false;
                if (success) this.tableData = data;
            },
            // 单击选中
            handleCurrentChange(row) {
                /* 选定商品 */
                if (row) {
                  if(row.taxRate.length > 4){
                    this.toast("当前税收分类编码存在多个税率，请手动更改", "warning");
                    row.taxRage = "0%"
                  }
                  let taxRateModel = Number(row.taxRate.replace('%', ''));
                  row.taxRateModel =taxRateModel ? taxRateModel / 100 : null;
                  this.$emit("handleCurrentGoods", row);
                }
                this.$refs.taxClassTable.setCurrentRow();
            }
        }
    }
</script>

<style scoped lang="scss">
    .mapping-wrap {
        display: flex;
        margin-top: -20px;
        padding: 0 24px 24px;
        flex-direction: column;

        .selection-area {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;

            label, .el-input {
                line-height: 32px;
                margin-right: 8px;
            }
        }

        .data-area {
            padding-top: 24px;
        }
    }
</style>
