/**
 * 商品信息
 *
 */

/**
 * MERCHANDISE_INFO_LIST
 * 商品信息列表字段
 */
export const MERCHANDISE_INFO_LIST = [
  {
    title: '创建时间',
    isTrue: true
  },
  {
    title: '税收商品编码',
    isTrue: true
  },
  {
    title: '税收分类简称',
    isTrue: true
  },
  {
    title: '商品名称',
    isTrue: true
  },
  {
    title: '规格型号',
    isTrue: true
  },
    
  {
    title: '单位',
    isTrue: true
  },
  {
    title: '单价',
    isTrue: true
  },
  {
    title: '优惠政策类型',
    isTrue: true
  },
  {
    title: '税率',
    isTrue: true
  },
  {
    title: '匹配度',
    isTrue: true
  },

  {
    title: '商品编码',
    isTrue: true
  },
  // {
  //   title: '所属大类',
  //   isTrue: true
  // },
  // {
  //   title: '所属中类',
  //   isTrue: true
  // },

  
  // {
  //   title: '是否特殊税率',
  //   isTrue: true
  // },



  // {
  //   title: '商品类型',
  //   isTrue: true
  // },
  
  // {
  //   title: '所属组织',
  //   isTrue: true
  // },
  // {
  //   title: '确认状态',
  //   isTrue: true
  // },
  // {
  //   title: '更新时段',
  //   isTrue: true
  // },
  // {
  //   title: '赋码方式',
  //   isTrue: true
  // },
  // {
  //   title: '数据来源',
  //   isTrue: true
  // },
  // {
  //   title: '操作',
  //   isTrue: true
  // },
  // {
  //   title: '税率(小规模纳税人)',
  //   isTrue: true
  // },
]

/**
 * 商品税率
 * @type {({label: string, value: number})[]}
 */
export const MERCHANDISE_TAX_RATE = [
  {
    value: 0.00,
    label: '0%'
  },
  {
    value: 0.01,
    label: '1%'
  },
  {
    value: 0.03,
    label: '3%'
  },
  {
    value: 0.04,
    label: '4%'
  },
  {
    value: 0.05,
    label: '5%'
  },
  {
    value: 0.06,
    label: '6%'
  },
  {
    value: 0.09,
    label: '9%'
  },
  {
    value: 0.10,
    label: '10%'
  },
  {
    value: 0.11,
    label: '11%'
  },
  {
    value: 0.13,
    label: '13%'
  },
  {
    value: 0.16,
    label: '16%'
  },
  {
    value: 0.17,
    label: '17%'
  },
  {
    value: 0.19,
    label: '19%'
  }]
