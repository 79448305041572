<template>
  <div class="page-main" v-loading="loading">
    <div class="tree-box">
      <el-scrollbar style="height: calc(100% + 17px);">
        <el-tree
          :data="treeData"
          :props="props"
          :check-strictly="checkStrictly"
          node-key="id"
          lazy
          :load="loadTree"
          :render-after-expand="renderExpand"
          @node-click="nodeClick">
        </el-tree>
      </el-scrollbar>
    </div>
    <el-form class="edit-select" ref="merchandiseForm" :model="info" :rules="merchandiseRules">
      <div class="edit-select-item">
        <el-form-item prop="merchandiseName" label="商品名称">
          <el-tooltip :content="info.merchandiseName" placement="top"
                      :disabled="!info.merchandiseName || (info.merchandiseName && info.merchandiseName.length <= 10)" >
            <el-input v-model.trim="info.merchandiseName" :disabled="propIsDisabled(info, 'merchandiseName')"
                      @input="handleInput" maxlength="50"></el-input>
          </el-tooltip>
          <span class="bizinfo-class" @click="fetchBizInfo()">智能赋码</span>
        </el-form-item>
        <el-form-item prop="taxClassCode" label="税收商品编码" required>
          <el-input v-model.trim="info.taxClassCode" placeholder="请输入税收商品编码">
            <!--            <i slot="suffix" class="el-icon-more" style="color: #409eff; cursor: pointer"-->
            <!--               @click="handleChooseTaxClassCode"></i>-->
          </el-input>
        </el-form-item>
        <el-form-item prop="taxClassName" label="税收分类名称">
          <el-input v-model="info.taxClassName"></el-input>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="usePreferentialPolicy" label="是否使用优惠政策">
          <el-select v-model="info.usePreferentialPolicy" :disabled="propIsDisabled(info, 'usePreferentialPolicy')">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="vatSprcialManage" label="优惠政策类型" v-if="info.usePreferentialPolicy == 1">
          <el-select v-model="info.vatSprcialManage" :disabled="propIsDisabled(info, 'vatSprcialManage')">
            <el-option v-for="i in vatSprcialManageOptions" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxRate" label="税率" required>
          <el-select v-model="info.taxRate" :disabled="propIsDisabled(info, 'taxRate')">
            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="isTaxPreferential" label="免税类型">
          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">
            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxSign" label="含税标志">
          <el-select v-model="info.taxSign" :disabled="propIsDisabled(info, 'taxSign')">
            <el-option v-for="i in taxSigns" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <!--商品分类 不要了-->
      </div>
      <div class="edit-select-item">
<!--        <el-form-item prop="uniqueCode" label="商品编码">-->
<!--          <el-input v-model="info.uniqueCode" :disabled="propIsDisabled(info, 'uniqueCode')" maxlength="32"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item prop="goodsSimpleCode" label="商品简码">
          <el-input v-model="info.goodsSimpleCode" :disabled="propIsDisabled(info, 'goodsSimpleCode')"
                    maxlength="32"></el-input>
        </el-form-item>
        <el-form-item prop="specification" label="规格型号">
          <el-input v-model="info.specification" :disabled="propIsDisabled(info, 'specification')"></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="计量单位">
          <el-input v-model="info.unit" :disabled="propIsDisabled(info, 'unit')"></el-input>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="description" label="说明">
          <el-input v-model="info.description" :disabled="propIsDisabled(info, 'description')"
                    style="width: 420px"
                    type="textarea"></el-input>
        </el-form-item>
      </div>
      <!--      <div class="edit-select-item">-->
      <!--        <el-form-item prop="orgId" label="所属组织">-->
      <!--          <organization-select :model="info" :disabled="propIsDisabled(info, 'orgId')" allLabel="请选择所属组织"></organization-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="uniqueCode" label="商品编码">-->
      <!--          <el-input v-model="info.uniqueCode" :disabled="propIsDisabled(info, 'uniqueCode')" maxlength="32"></el-input>-->
      <!--        </el-form-item>        -->
      <!--        <el-form-item v-if="!isRetail" prop="merchandiseName" label="商品名称">-->
      <!--          <el-input v-model.trim="info.merchandiseName" :disabled="propIsDisabled(info, 'merchandiseName')" maxlength="50" @input="handleInput"></el-input>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item v-if="isRetail" prop="isTaxPreferential" label="是否享受特殊税率">-->
      <!--          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">-->
      <!--            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <!--      <div class="edit-select-item" v-if="isRetail">-->
      <!--        <el-form-item prop="merchandiseName" label="商品名称">-->
      <!--          <el-input v-model.trim="info.merchandiseName" :disabled="propIsDisabled(info, 'merchandiseName')" @input="handleInput" maxlength="50"></el-input>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="merchandiseCategory" v-if="isRetail" label="商品类型">-->
      <!--          <el-select v-model="info.merchandiseCategory" :disabled="propIsDisabled(info, 'merchandiseCategory')" placeholder="请选择商品类型">-->
      <!--            <el-option v-for="item in MerchandiseCategory" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="specialManagement" label="特殊税率内容">-->
      <!--          <el-select v-model="info.specialManagement" :disabled="propIsDisabled(info, 'specialManagement')">-->
      <!--            <el-option v-for="i in content" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <!--      <div class="edit-select-item">-->
      <!--        <el-form-item prop="taxClassCode" label="税收分类编码" required>-->
      <!--          <el-input v-model.trim="info.taxClassCode" placeholder="请输入税收分类编码">-->
      <!--            <i slot="suffix" class="el-icon-more" style="color: #409eff; cursor: pointer" @click="handleChooseTaxClassCode"></i>-->
      <!--          </el-input>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="taxClassName" label="税收分类名称">-->
      <!--          <el-input v-model="info.taxClassName"></el-input>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="taxSign" label="含税标志">-->
      <!--          <el-select v-model="info.taxSign" :disabled="propIsDisabled(info, 'taxSign')">-->
      <!--            <el-option v-for="i in taxSigns" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <!--      <div class="edit-select-item">-->
      <!--        <el-form-item prop="taxRateModel" label="税率" required>-->
      <!--          <el-select v-model="info.taxRateModel" :disabled="propIsDisabled(info, 'taxRate')">-->
      <!--            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="taxRateModel" label="税率（小规模纳税人）" >-->
      <!--          <el-select v-model="info.ssTaxRateModel" :disabled="propIsDisabled(info, 'taxRate')">-->
      <!--            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="price" label="商品单价">-->
      <!--          <el-input v-model="info.price" :disabled="propIsDisabled(info, 'price')"></el-input>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <!--      <div class="edit-select-item">-->
      <!--        <el-form-item  prop="priceVariable" label="单价是否可修改">-->
      <!--          <el-select v-model="info.priceVariable" :disabled="propIsDisabled(info, 'priceVariable')">-->
      <!--            <el-option v-for="i in priceVariableOptions" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="specification" label="规格型号">-->
      <!--          <el-input v-model="info.specification" :disabled="propIsDisabled(info, 'specification')"></el-input>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item prop="unit" label="商品单位">-->
      <!--          <el-input v-model="info.unit" :disabled="propIsDisabled(info, 'unit')"></el-input>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <!--      <div class="edit-select-item" v-if="!isRetail">-->
      <!--        <el-form-item  prop="isTaxPreferential" label="是否享受特殊税率">-->
      <!--          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">-->
      <!--            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item  prop="specialManagement" label="特殊税率内容">-->
      <!--          <el-select  v-model="info.specialManagement" :disabled="propIsDisabled(info, 'specialManagement')">-->
      <!--            <el-option v-for="i in content" :key="i.value" :label="i.label" :value="i.value"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item  prop="specialManagement" label="">-->
      <!--          <div style="width:200px;"></div>-->
      <!--        </el-form-item>-->
      <!--      </div>-->
      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
          <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveMerchandise" type="primary">
            保存
          </el-button>
        </div>
      </div>
    </el-form>
    <el-dialog append-to-body title="选择税收分类编码" :visible.sync="taxClassVisible" :show-close="false">
      <tax-class-code-mapping @handleCurrentGoods="handleCurrent"></tax-class-code-mapping>
    </el-dialog>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import TaxClassCodeMapping from './TaxClassCodeMapping';
import {
  insertMerchandise,
  updateMerchandise,
  checkUniqueCode,
  getFillSerialNo,
  getMiddleMerchandise,
  getTaxCodeList,
  getBizInfo
} from '@/service/merchandise';
import {MERCHANDISE_TAX_RATE} from '@/content/merchandiseInfo';

export default {
  name: 'MerchandiseEdit',
  components: {OrganizationSelect, TaxClassCodeMapping},
  props: {
    isRetail: Boolean,
    merchandise: {
      type: Object,
      default: () => {
      }
    },
    content: Array,
    scene: undefined
  },
  watch: {
    'info.specialManagement'() {
      if (this.info.specialManagement != '免税' && this.info.specialManagement != '不征税') {
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
      if ((this.info.specialManagement == '免税' || this.info.specialManagement == '不征税') && this.info.specialManagement != '') {
        this.info.taxRateModel = this.info.specialManagement;
      }
    },
    'info.taxRateModel'() {
      if (this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' && this.info.taxRateModel != '' && (this.info.specialManagement == '免税' || this.info.specialManagement == '不征税')) {
        this.info.isTaxPreferential = '0';
      }
    },
    'info.isTaxPreferential'() {
      if (this.info.isTaxPreferential == '0') {
        this.info.specialManagement = '';
        // 税率重置
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
    }
  },
  computed: {
    priceDisabled() {
      var that = this.info;
      let price = this.info.price;
      if (price == null || price === '') {
        that.priceVariable = '1';
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      renderExpand: false,        //tree组件是否在第一次展开节点后渲染
      checkStrictly: true,        //tree组件父子节点 不 互相关联
      treeData: [],               //功能权限树
      props: {                    //tree组件对应
        // children: 'children',
        label: 'goodsName',
        isLeaf: "isLeaf"
      },
      loading: false,
      remoteLoading: false,
      info: this.merchandise,
      middleKinds: [],
      MerchandiseCategory: [{value: '', label: '全部'}, ...this.$store.state.enums['MerchandiseCategory']],
      taxRates: MERCHANDISE_TAX_RATE,
      isTaxPreferentialTypes: [
        {value: 1, label: '享受'},
        {value: 0, label: '不享受'}
      ],
      priceVariableOptions: [
        {value: 1, label: '是'},
        {value: 0, label: '否'}
      ],
      vatSprcialManageOptions: [ // 优惠政策类型
        {value: 1, label: '不征税'},
        {value: 2, label: '征税'},
      ],
      taxSigns: [
        {value: 0, label: '是'},
        {value: 1, label: '否'}
      ],
      merchandiseRules: {
        orgId: [{required: true, message: '请选择组织！'}],
        merchandiseName: [
          {required: true, message: '请输入商品名称!'},
          {min: 1, max: 50, message: '长度为 1~50 个字符!', trigger: 'blur'}
        ],
        uniqueCode: [
          {required: true, message: '请输入商品编码!'},
          {validator: this.checkUnique, trigger: 'blur'}
        ],
        price: [
          {
            pattern: /^([-+])?\d+(\.[0-9]{1,8})?$/,
            message: '请输入正确的单价'
          }
        ],
        taxClassCode: [{required: true, message: '请输入税收分类编码!'}],
        taxRateModel: [{required: true, message: '请选择税率!'}],
        specialManagement: [{validator: this.specialManagement, trigger: 'blur'}]
      },
      taxClassVisible: false,
      saveBtnLoading: false
    };
  },
  created() {
    if (this.info.uniqueCode == undefined) this.getUniqueCode();
    // 获取树
    getTaxCodeList({goodsCode: 0}).then((res) => {
      if (res.success) {
        this.treeData = res.data || []
      }
    });
  },
  methods: {
    // 树节点点击回调
    nodeClick(node) {
      if (node.isParent == "N") {
        this.$set(this.info, "merchandiseName", node.goodsName) // 商品名称
        this.$set(this.info, "taxClassCode", node.goodsCode) // 税收商品编码
        this.$set(this.info, "taxClassName", node.goodsSimpleName) // 税收分类名称
        this.$set(this.info, "usePreferentialPolicy", 0) // 是否使用优惠政策
        this.$set(this.info, "vatSprcialManage", 1) // 优惠政策类型
        this.$set(this.info, "taxRate", node.vatRate) // 税率
        this.$set(this.info, "isTaxPreferential", 0) // 免税类型
        this.$set(this.info, "taxSign", 0) // 含税标志
        // this.$set(this.info, "uniqueCode", '') // 商品编码
        this.$set(this.info, "goodsSimpleCode", '') // 商品简码
        this.$set(this.info, "specification", '') // 规格型号
        this.$set(this.info, "unit", '') // 计量单位
        this.$set(this.info, "description", node.illustrateStr) // 说明
        this.info.matchDegree="100%"
      }
    },
    // 加载树
    loadTree(node, resolve) {
      let goodsCode = node.data.goodsCode
      if (goodsCode || goodsCode === 0) {
        getTaxCodeList({goodsCode: goodsCode}).then((res) => {
          if (res.success) {
            let list = res.data.map((item) => {
              return {
                ...item,
                isLeaf: item.isParent == "N"
              }
            })
            resolve(list);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    },
    handleInput(e) {
      this.$forceUpdate();
    },
    // 中类名称触发选中
    handleChande(val) {
      this.info.parentId = val;
    },
    // 匹配中类名称
    handleMiddleKindQuery(val) {
      if (!this.info.orgId) {
        return this.toast('请先勾选所属组织信息', 'warning');
      }
      if (val == null || val == undefined || val == '') {
        return;
      }
      this.remoteLoading = true;
      getMiddleMerchandise({orgId: this.info.orgId, name: val}).then((res) => {
        this.remoteLoading = false;
        if (res.success) {
          this.middleKinds = res.data;
        } else {
          this.middleKinds = [];
        }
      });
    },
    // 填充商品编码
    getUniqueCode() {
      this.loading = true;
      getFillSerialNo().then(({success, data}) => {
        this.loading = false;
        if (success) this.info.uniqueCode = data;
      });
    },
    // 校验特殊税率
    checkTaxPreferentialContent(rule, value, callback) {
      if (!value && this.info.isTaxPreferential == '1') {
        return callback(new Error('特殊税率内容不能为空!'));
      }
      callback();
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },
    // 重置表单
    resetForm() {
      this.$refs.merchandiseForm.resetFields();
    },
    // 单选商品
    handleCurrent(row) {
      this.info.taxClassCode = row.taxClassCode;
      this.info.taxClassName = row.taxClassName;
      let taxRateModel = Number(row.taxRate.replace('%', ''));
      this.info.taxRateModel = taxRateModel ? taxRateModel / 100 : null;
      if (row.specialManagement != '') {
        this.info.isTaxPreferential = '1';
        this.info.specialManagement = row.specialManagement;
      }
      this.taxClassVisible = false;
    },
    // 商品编码唯一性校验
    checkUnique(rule, value, callback) {
      if (!value) return callback(new Error('商品编号不允许为空!'));
      const params = {
        uniqueCode: this.info.uniqueCode,
        id: this.info.id ? this.info.id : 0,
        orgId: this.merchandise.orgId
      };
      if (!this.merchandise.orgId) {
        return callback();
      }
      checkUniqueCode(params).then((res) => {
        if (res.success) {
          if (res.data) {
            callback(new Error('已存在相同编号商品'));
            return;
          }
        }
        callback();
      });
    },
    // 税收分类编码 补零
    addZeroTaxClassCode(taxClassCode) {
      while (taxClassCode.length < 19) {
        taxClassCode = taxClassCode + '0';
      }
      return taxClassCode;
    },
    fetchBizInfo(){
      Object.keys(this.info).forEach(key => {
        if (key!=='merchandiseName') {
          this.$set(this.info, key, '');
        }
      });
      if(this.info.merchandiseName==null || this.info.merchandise==''){
        return this.toast('商品名称不允许为空', 'warning');
      }
      let param={
        "goodsName": encodeURIComponent(this.info.merchandiseName)
      }
      getBizInfo(param).then((res) =>{
        if(res.success){
          if(res.data!=null){
            var resp=res.data
            const taxRateDecimal = parseFloat(resp.goodsTaxRate) / 100;
            console.log(taxRateDecimal)
            this.$set(this.info, "merchandiseName", resp.classificationName) // 商品名称
            this.$set(this.info, "taxClassCode", resp.goodsCode) // 税收商品编码
            this.$set(this.info, "taxClassName", resp.classificationSimpleName) // 税收分类名称
            this.$set(this.info, "usePreferentialPolicy", 0) // 是否使用优惠政策
            this.$set(this.info, "vatSprcialManage", resp.vatSpecialManagement) // 优惠政策类型
            this.$set(this.info, "taxRate", taxRateDecimal) // 税率

            this.$set(this.info, "isTaxPreferential", 0) // 免税类型
            this.$set(this.info, "taxSign", 0) // 含税标志
            // this.$set(this.info, "uniqueCode", '') // 商品编码
            this.$set(this.info, "goodsSimpleCode", '') // 商品简码
            this.$set(this.info, "specification", '') // 规格型号
            this.$set(this.info, "unit", '') // 计量单位
            // this.$set(this.info, "description", node.illustrateStr) // 说明
            this.info.taxRateModel=taxRateDecimal
            this.info.matchDegree=resp.selectionPercent
          }
        }

      })

    },
    // 点击提交 -> 参数校验
    handleSaveMerchandise() {
      this.$refs.merchandiseForm.validate((valid) => {
        if (valid) this.submitMerchandise();
      });
    },
    // 选择税收分类编码
    handleChooseTaxClassCode() {
      // console.log(this.info,'this.info')
      let tax = this.propIsDisabled(this.info, 'taxClassCode');
      // console.log(tax,'tax')
      if (tax == true) {
        return this.taxClassVisible;
      }
      this.taxClassVisible = true;
    },
    // 提交商品信息
    async submitMerchandise() {
      // loading保存按钮阻止反复提交
      this.saveBtnLoading = true;
      // 税收分类编码后面自动补充0到19位
      let realTaxClassCode = this.info.taxClassCode;
      this.info.taxClassCode = this.addZeroTaxClassCode(realTaxClassCode);

      // 税率补正
      this.info.taxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.taxRateModel;
      this.info.ssTaxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.ssTaxRateModel;
      // 新增字段给默认值
      if (this.info.isTaxPreferential == undefined || this.info.isTaxPreferential == '0') {
        this.info.isTaxPreferential = 0;
        this.info.specialManagement = '';
      }

      // 添加商品
      const {success} = this.info.id > 0 ? await updateMerchandise(this.info) : await insertMerchandise(this.info);
      const message = this.info.id > 0 ? '修改' : '新增';
      this.saveBtnLoading = false;
      if (success) {
        this.toast(`${message}商品信息成功`, 'success', () => this.handleCancel('success'));
        if (this.scene == 'Billing' && this.isRetail) {
          this.$emit('edit-finish', this.info);
        }
      } else {
        this.toast(`${message}商品信息失败`, 'error');
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
  padding-top: 2px;
}

.edit-select {
  flex: 1;
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 24px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: 20px;
    .el-form-item {
      margin-bottom: 20px;
    }
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.page-main {
  padding: 0 24px;
  display: flex;

  .tree-box {
    height: 450px;
    min-width: 250px;
    margin-right: 20px;
    overflow: hidden;

    * {
      //border:1px solid red;
    }
  }

  .tax-class-code {
    height: 32px;
    width: 170px;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    align-content: center;
    border: 1px solid #dcdfe6;
    justify-content: space-between;

    i {
      color: #3d94ff;
      line-height: 32px;
      margin-right: -4px;
    }
  }
}
.bizinfo-class{
  margin-left: 5px;
  color:#3d94ff;
  cursor: pointer;
}
</style>
